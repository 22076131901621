<div class="content">
    <form [formGroup]="AddForm">
        <div class="d-header mb-5">
            <b>{{data ? "Edit" : "Add"}} Category</b>
            <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png" alt="" class="clss" mat-dialog-close>
        </div>
        <div mat-dialog-content style="width: 300px;">
            <div style="width: 275px;float: right;">
                <span class="texttt">
                    Enter Category Name
                </span>
                <div class="w-50">
                    <input type="text" placeholder=" Sports Category " class="inpt" formControlName="ground_name" appAlphabetOnly> 
                </div>
            </div>
            <div style="width: 275px;float: right; margin-top: .8rem;">
                <span class="texttt">
                    Enter placement Name
                </span>
                <div class="w-50">
                    <input type="text" placeholder=" Placement Number" class="inpt" formControlName="placementNo"> 
                </div>
            </div>

            <div>
                <!-- <img src="../../../assets/Sportsbay_Vendor_Web_Panel_Assets/upload_ic.png" alt=""> -->
                <div class="profile-pic-pnl">
                    <div class="profile-pic">
                        <img [src]="url">
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-3">
            <div mat-dialog-actions>
                <input type='file' (change)="onSelectFile($event)" class="fileUpload" accept="image/*">
            </div>
            <!-- <button mat-button mat-dialog-close class="save" >Upload </button> -->
            <button class="save2 "><i class=" fa fa-upload"></i> Upload</button>

            <button mat-button mat-dialog-close class="save" (click)="AddCategory()">{{!data ? "Save" : "Update"}}</button>
            <!-- <button *ngIf = "data" mat-button mat-dialog-close class="save" (click)="AddCategory()">Update</button> -->

        </div>
    </form>
</div>