import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) { }

  baseurl = environment.baseUrl;

  get(url: any) {
    return this.http.get(`${this.baseurl}/auth/admin/${url}`);
  }

  post(url: any, data: any) {
    return this.http.post(`${this.baseurl}/auth/admin/${url}`, data);
  }


  delete(url: any) {
    return this.http.delete(`${this.baseurl}/auth/admin/${url}`);
  }
  put(url: any, data: any) {
    return this.http.put(`${this.baseurl}/auth/admin/${url}`, data);
  }


  getCommon(url: any) {
    return this.http.get(`${this.baseurl}/auth/common/${url}`);
  }

  postCommon(url: any, data: any) {
    return this.http.post(`${this.baseurl}/auth/common/${url}`, data);
  }
  
  
  isLogin() {
    var token;
    token = sessionStorage.getItem("GroundBookingAdmin");
    if (token) return true;
    else return false;
  }
}
