import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from "src/app/service/api.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material/dialog";
import { ImgViewComponent } from "../img-view/img-view.component";

@Component({
  selector: "app-comman",
  templateUrl: "./comman.component.html",
  styleUrls: ["./comman.component.css"],
})
export class CommanComponent {
  // url = "assets/Sportsbay_Vendor_Web_Panel_Assets/upload_ic.png";


  [x: string]: any;
  //  MULTIEMAGE UPLOADER
  // public dialogRef!: MatDialogRef<CommanComponent>;
  imgChange: boolean | undefined;
  multiple1: never[] | undefined;
  uploadProfileImage: any;
  profile_image: any;
  cityList: any;
  submitted: Boolean = false;
  selected = "option2";
  saveEditData: any;
  disableBlock : Boolean = false
  multipleFiles1(event: any) {
    this.imgChange = true;
    this.multiple1 = [];
    var multipleFiles = event.target.files;
    this.uploadProfileImage = event.target.files[0];
    if (multipleFiles) {
      for (var file of multipleFiles) {
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          this.profile_image = e.target.result;
          console.log(this.profile_image);
        };
        multipleReader.readAsDataURL(file);
      }
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private toaster: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommanComponent>
  ) {}


  role!: number
  userData: any
  elementData:any
  ngOnInit() {
    this.citylist();
    this.reasonslist();
    this.patchData()
    this.elementData=this.data?.element
    
    this.userData = sessionStorage.getItem('GroundBookingAdmin')
    this.userData = JSON.parse(this.userData)
    if (this.userData) {
      this.role = this.userData.isSubAdmin
      console.log(this.userData, 'aaa')
    }
    
  }

  checkPermission(feild: any): boolean {
    return this.userData?.moduleAccess?.includes(feild)
  }
  cityForm: FormGroup = new FormGroup({
    countryISO: new FormControl("AE", Validators.required),
    cityName: new FormControl("", Validators.required),
  });

  // ....For get all city list ........
  citylist() {
    this.api
      .postCommon("get_city_list_by_country", { countryCode: "AE" })
      .subscribe({
        next: (res: any) => {
          this.cityList = res.response;
        },
      });
  }

  // .........For add city ....

  addCity() {
    this.submitted = true;

    if (this.cityForm.invalid) {
      return;
    }
    let data = this.cityForm.value;
    this.api.post("add_city", data).subscribe({
      next: (res: any) => {
        this.toaster.success(res.message);
        this.dialogRef.close(); // <- Close the mat dialog
      },
      error: (err: any) => {
        this.toaster.error(err.error.message);
        console.log(err.error.message);
      },
    });
  }

  commissionForm= new FormGroup({
     commisson:new FormControl()

  })

  patchData(){
     console.log(this.data)
    this.commissionForm.patchValue({
      commisson:this.data?.element?.commission

    })

  }
addCommssion(){
  if(this.commissionForm.invalid){
    return

  }
   let  formData =this.commissionForm.value
   let  data ={
    vendorId:this.data?.element?._id,
    commission : formData.commisson,
   } 
   this.api.post("update_vendor_commission", data).subscribe({
    next: (res: any) => {
      this.toaster.success(res.message);
      this.dialogRef.close(); // <- Close the mat dialog
    },
    error: (err: any) => {
      this.toaster.error(err.error.message);
      console.log(err.error.message);
    },
  });
}

  // ...... For Reject Reason List........
  rejectReasons: any;
  reasonslist() {
    this.api.get("get_reject_reason_list").subscribe({
      next: (res: any) => {
        this.rejectReasons = res.response.rejectReasons;
      },
    });
  }
  selectReason = new FormGroup({
    reason: new FormControl("", Validators.required),
  });

  selectReasonButton() {
    console.log(this.selectReason.value);
    let data = {
      vendorId: this.data.element,
      vendorStatus: 2,
      reason: this.selectReason.value.reason
    };
    console.log(data);

    this.api.post("change_vendor_status", data).subscribe({
      next: (res: any) => {
        this.data = res.response;
        this.toaster.success(res.message);

        this.dialogRef.close()
      },
      error: (err: any) => {
        console.log(err.error.message);
      },
    });

  }

  //  url1 ="assets/logo.png"
  url1 = "assets/images (1).png";

  blockkUser() {
    this.api.post(`${this.data.api}`, { _id: this.data.id }).subscribe({
      next: (res: any) => {
        this.toaster.success(res.message)
      },
      error: (error: any) => {
        console.log(error.message)
        this.toaster.error(error.error.message);
      }
    })
  }


  OpenImage(url: any) {
    console.log(url, "** jiii*")
    window.open(url, '_blank');
  }

  imgView(data: any): void {
    console.log(data, " hey  shubh")
    const dialogRef = this.dialog.open(ImgViewComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      // this.groundCategorylist()
    })
  }


  disable(type : any){
    if(type == 1){
      this.disableBlock = false
      console.log(this.disableBlock)
    }if(type == 0){
      this.disableBlock = true
    }
  }
}
