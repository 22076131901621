import { Component, Inject } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from "ngx-toastr";
import { Data, Router } from "@angular/router";
import {
  FormControl,
  FormGroup,
  Validators,
  FormControlName,
} from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent {

  url = "assets/Sportsbay_Vendor_Web_Panel_Assets/upload_ic.png";
  submitted: Boolean = false
  CategoryList: any
  uploadProfileImage: any;
  saveEditData: any;

  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<CategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.saveEditData = this.data
    if (this.data) {
      this.AddForm.patchValue({
        ground_name: this.saveEditData.name,
        placementNo: this.saveEditData.placement_no,
        // image: this.saveEditData.image
      });
      this.url = this.saveEditData.image;
    }
  }

  onSelectFile(event: any) {
    this.uploadProfileImage = event.target.files[0]
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.uploadImage(this.uploadProfileImage);

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  }

  AddForm: FormGroup = new FormGroup({
    ground_name: new FormControl("", Validators.required),
    placementNo: new FormControl("", Validators.required),
    image: new FormControl("")
  });
  get f() {
    return this.AddForm.controls
  }

  image: any
  uploadImage(img: any) {
    // debugger
    let data = new FormData();
    data.append('upload_admin_file', this.uploadProfileImage);
    this.api.post('upload_file', data).subscribe((res: any) => {
      this.url = res.response
    });
    this.AddForm.patchValue({
      image: this.url
    })
  }

  AddCategory() {
    // debugger
    this.submitted = true
    if (this.AddForm.invalid) {
      return
    }
    let data = this.AddForm.value
    data.image = this.url;
    // console.log(data)

    if (!this.saveEditData) {
      this.api.post('add_ground_category', data).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.dialogRef.close(); // <- Close the mat dialog

        }, error: (err: any) => {
          // this.toaster.error(err.error.message)
          console.log(err.error.message)
        }
      })
    } 
    else {
      data.id = this.saveEditData._id

      this.api.post('edit_ground_category', data).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.dialogRef.close(); // <- Close the mat dialog
        }, error: (err: any) => {
          // this.toaster.error(err.error.message)
          console.log(err.error.message)
        }
      })
    }

  }
}
