<div>
    <div class="d-header mb-3">
        <b>Add Promo Code </b>
        <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
            alt class="clss" mat-dialog-close>
    </div>
    <form [formGroup]="addPromoCodeForm">
        <div class="row">
            <div class="col-12 offset-md-1  col-md-4">

                <div class=" ground1 ">
                    <span>Start Date</span>
                    <mat-form-field color="accent" appearance="fill"
                        class="my-date mat-mdc-text-field-wrapper ">
                        <input matInput [matDatepicker]="picker1"
                            placeholder="DD/MM/YY" formControlName="startDate"
                            [min]="minDate" required>
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="submitted && f.startDate.errors">
                        <div *ngIf="submitted && f.startDate.errors['required']">Please
                            Select Start Date</div>

                    </div>

                </div>
                <div class="ground1">
                    <span>Name</span>
                    <div><input type="text" placeholder="Name"
                            formControlName="promoCodeTitle"
                            (keypress)="onKeyPress($event)"></div>
                    <div class="text-danger"
                        *ngIf="submitted && f.promoCodeTitle.errors">
                        <div
                            *ngIf="submitted && f.promoCodeTitle.errors['required']">Please
                            Enter promoCode Title</div>

                    </div>
                </div>
                <div class="ground1" *ngIf="isShowTextArea === 0">
                    <span> Maximum discount</span>
                    <div><input type="text" placeholder="maximumDiscount"
                            formControlName="maximumDiscount"
                            onkeypress="return event.charCode > 47 && event.charCode < 58;"
                            minLength="1" maxLength="5" required>
                    </div>

                    <div class="text-danger"
                        *ngIf="submitted  && f.maximumDiscount.errors">
                        <div
                            *ngIf="submitted && f.maximumDiscount.errors['required']">Please Maximum Discount Amout</div>

                    </div>
                </div>

                <div class="ground1">
                    <span>Usage Limit</span>
                    <div><input type="text" placeholder="Usage Limit"
                            formControlName="orderValueLimit"
                            onkeypress="return event.charCode > 47 && event.charCode < 58;"></div>
                    <div class="text-danger"
                        *ngIf="submitted && f.orderValueLimit.errors">
                        <div
                            *ngIf="submitted && f.orderValueLimit.errors['required']">Please
                            Enter OrderValue Limit
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-12 offset-md-2 col-md-4">
                <div class=" ground1 ">
                    <span>End Date</span>
                    <mat-form-field color="accent" appearance="fill"
                        class="my-date mat-mdc-text-field-wrapper">
                        <input matInput [matDatepicker]="picker2"
                            placeholder="DD/MM/YY" formControlName="endDate"
                            [min]="minDate" required>
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="submitted && f.endDate.errors">
                        <div *ngIf="submitted && f.endDate.errors['required']">Please
                            Select End Date</div>

                    </div>
                </div>

                <div class="ground1">
                    <span>Minimum Booking
                        Charges</span>
                    <div><input type="text"
                            placeholder="Minimum Booking Charges"
                            formControlName="minimumOrderAmt"
                            minlength="1" maxlength="5"
                            onkeypress="return event.charCode > 47 && event.charCode < 58;">
                    </div>
                    <div class="text-danger"
                        *ngIf="submitted && f.minimumOrderAmt.errors">
                        <div
                            *ngIf="submitted && f.minimumOrderAmt.errors['required']">Please
                            Minimum Order Amt</div>

                    </div>
                </div>
                <div class="ground1 p-0" >
                    <span>Discount Type</span>
                    <div>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="discountType"
                            (change)="listShow()">
                            <div class="radio-btn">
                                <mat-radio-button [value]="0"> Percentage
                                </mat-radio-button>
                                <mat-radio-button [value]="1">Flat</mat-radio-button>
                            </div>
                       
                        </mat-radio-group>
                    </div>
                    <div class="text-danger"
                        *ngIf="submitted && f.discountType.errors">
                        <div
                            *ngIf="submitted && f.discountType.errors['required']">Please
                            Select discount Type</div>

                    </div>
                </div>

                <div class="ground1" *ngIf="isShowTextArea === 0">
                    <span>Discount Percentage</span>
                    <div>
                        <input type="text" 
                               placeholder="Discount Percentage"
                               formControlName="discount"
                               (keypress)="restrictZeroAtFirstPosition($event)"
                               min="1" 
                               max="99"
                               minlength="1"
                               maxlength="2"
                               oninput="this.value = this.value > 99 ? 99 : this.value"
                               onkeypress="return event.charCode > 47 && event.charCode < 58;"
                               onpaste="return false;"
                               ondrop="return false;">
                    </div>
                    
                    <div class="text-danger"
                        *ngIf="submitted && f.discount.errors">
                        <div *ngIf="submitted && f.discount.errors['required']">Please
                            Enter discount Percentage</div>

                    </div>
                </div>
                <div class="ground1" *ngIf="isShowTextArea === 1">
                    <span>Discount Flat</span>
                    <div><input type="text" placeholder="Discount Flat"
                            formControlName="discount"
                            onkeypress="return event.charCode > 47 && event.charCode < 58;"
                            minlength="1" maxlength="5">
                    </div>
                    <div class="text-danger"
                        *ngIf="submitted && f.discount.errors">
                        <div *ngIf="submitted && f.discount.errors['required']">Please
                            Enter discount </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-md-1 col-md-10 text-sm-start">
                <span>Description</span>
                <div>
                    <textarea name id cols="30" rows="2"
                        placeholder="description" formControlName="description"
                        class="w-100"></textarea>
                    <!-- <input type="text" placeholder="description" formControlName="description"> -->
                </div>

                <div class="text-danger"
                    *ngIf="submitted && f.description.errors">
                    <div *ngIf="submitted && f.description.errors['required']">Description
                        is Required</div>
                </div>
            </div>
        </div>
    </form>
    <button class="btns w-25 mt-3" (click)="addPromoFormSubmit()">{{!data ?
        "Save" : "Update"}}</button>

</div>