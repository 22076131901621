<div mat-dialog-content class="prnt">

  <div class="ground1">
    <div class="d-flex justify-content-between ">
      <div>
        <label style="font-size: 20px">Delete</label>
      </div>

      <div>
        <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png" alt mat-dialog-close />
      </div>
    </div>
    <div>
      <label style="font-size: 15px; margin: 1rem 0rem 2rem;">Are you sure you want to Delete ?
      </label>
    </div>
    <div class="d-flex mt-3 justify-content-between">
      <button class="save2" (click)="CommonDelete()" mat-dialog-close>Yes</button>
      <button class="save2" data-bs-dismiss="modal" mat-dialog-close>No</button>

       </div>
  </div>
</div>


<div mat-dialog-content class="prnt" *ngIf="data.type == 1">

  <div class="ground1">
    <div class="d-flex justify-content-between ">
      <div>
        <label style="font-size: 20px">Delete</label>
      </div>

      <div>
        <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png" alt mat-dialog-close />
      </div>
    </div>
    <div>
      <label style="font-size: 15px; margin: 1rem 0rem 2rem;">Are you sure you want to Delete ?
      </label>
    </div>
    <div class="d-flex mt-3 justify-content-between">
      <button class="save2" (click)="CommonDelete()" mat-dialog-close>Yes</button>
      <button class="save2" data-bs-dismiss="modal" mat-dialog-close>No</button>

       </div>
  </div>
</div>