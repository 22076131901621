import { Component } from "@angular/core";
import { DialogRef } from "@angular/cdk/dialog";
import { ApiService } from "src/app/service/api.service";
import { ToastrService } from "ngx-toastr";
import { Data } from "@angular/router";
import { Inject } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormControlName,
} from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from "@angular/material/dialog";
@Component({
  selector: "app-add-banner",
  templateUrl: "./add-banner.component.html",
  styleUrls: ["./add-banner.component.css"],
})
export class AddBannerComponent {
  url = "assets/Sportsbay_Vendor_Web_Panel_Assets/upload_ic.png";
  submitted: Boolean = false;
  AdvertisementList: any;
  uploadProfileImage: any;
  saveEditData: any;
  start_date: any;
  end_date: any;

  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<AddBannerComponent>,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    this.dateAdapter.setLocale("en-GB");
  }

  bannerForm: FormGroup = new FormGroup({
    title: new FormControl("", Validators.required),
    link: new FormControl("", Validators.required),
    start_date: new FormControl(""),
    end_date: new FormControl(""),
    banner_image: new FormControl(""),
  });

  ngOnInit() {
    this.saveEditData = this.data;
    if (this.data) {
      this.bannerForm.patchValue({
        title: this.saveEditData.title,
        link: this.saveEditData.link,
      });
      (this.start_date = new Date(Number(this.saveEditData.start_date))),
        (this.end_date = new Date(Number(this.saveEditData.end_date))),
        (this.url = this.saveEditData.banner_image);
    }
  }

  onSelectFile(event: any) {
    this.uploadProfileImage = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.uploadImage(this.uploadProfileImage);

      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }

  banner_image: any;
  uploadImage(img: any) {
    let data = new FormData();
    data.append("upload_admin_file", this.uploadProfileImage);
    this.api.post("upload_file", data).subscribe((res: any) => {
      this.url = res.response;
    });
    this.bannerForm.patchValue({
      banner_image: this.url,
    });
  }
  get f() {
    return this.bannerForm.controls;
  }
 
  addAdvertisement() {
    this.submitted = true;
    if (this.bannerForm.invalid) {
      return;
    }
    let data = this.bannerForm.value;
    data.banner_image = this.url;
    data.start_date = new Date(this.start_date).getTime();
    data.end_date = new Date(this.end_date).getTime();
    if (!this.saveEditData) {
      this.api.post("add_advertisement", data).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.dialogRef.close(); // <- Close the mat dialog
        },
        error: (err: any) => {
          this.toaster.error(err.error.message);
          console.log(err.error.message);
        },
      });
    } else {
      data.id = this.saveEditData._id;
      this.api.post("edit_advertisement", data).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.dialogRef.close(); // <- Close the mat dialog
        },
        error: (err: any) => {
          // this.toaster.error(err.error.message)
          console.log(err.error.message);
        },
      });
    }
  }
}
