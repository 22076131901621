import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-img-view',
  templateUrl: './img-view.component.html',
  styleUrls: ['./img-view.component.css']
})
export class ImgViewComponent {
   imageData:any

  constructor(public dialogRef: MatDialogRef<ImgViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {}


     ngOnInit(){
      console.log(this.data," hh")
      this.imageData=this.data
      
     }

}
