import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appLowercase]'
})
export class LowercaseDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input') onInput() {
    this.el.nativeElement.value = this.el.nativeElement.value.toLowerCase();
  }

}
