import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  @Input() allowDecimal: boolean = false;

  constructor() {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const input = event.target;
    const value = input.value;

    // Allow only digits and optionally a decimal point
    // <input type="text" appOnlyNumber [allowDecimal]="true" placeholder="Only Numbers (with Decimal)" />

    input.value = this.allowDecimal ? value.replace(/[^0-9.]/g, '') : value.replace(/[^0-9]/g, '');
  }

}
