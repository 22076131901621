import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authService: ApiService, private toastr: ToastrService, private router: Router) { }
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isLogin()) {
      this.toastr.info('Please Log In!');
      // this.router.navigate(['/login']);
      this.router.navigateByUrl('/login')
      return false;
    }
    // logged in, so return true
    this.authService.isLogin();
    return true;
  }
}


// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuard implements CanActivate {
//   constructor ( private apiService : ApiService,
//     private router : Router){}

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//       const currentUser = this.apiService.isLogin()
//       if (currentUser) {
// //         return true
// //       }
//       this.router.navigate(['/login']);
//       return false;  
// }
// }
