import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


@Component({
  selector: 'app-add-promo',
  templateUrl: './add-promo.component.html',
  styleUrls: ['./add-promo.component.css']
})
export class AddPromoComponent {
  // data: any
  patchValueData: any
  hideFiled: any
  saveEditData: any;

  submitted: boolean = false
  isShowTextArea: number = 0;
  minDate = new Date() 
    constructor(
      private api:ApiService,
      private toaster:ToastrService,
      private router:Router,
      public dialogRef: MatDialogRef<AddPromoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: Data,
      
    ){
    }

     ngOnInit(){
      this.saveEditData = this.data
      this.patchPromoCodeData()
       }

addPromoCodeForm= new FormGroup({
promoCodeTitle: new FormControl('',Validators.required),
discountType: new FormControl('',Validators.required),
discount: new FormControl('',Validators.required),
maximumDiscount: new FormControl(''),
minimumOrderAmt:new FormControl('',Validators.required),
startDate:new FormControl(),
endDate:new FormControl(),
orderValueLimit:new FormControl('',Validators.required),
promoCodeId: new FormControl(),
description:new FormControl('',Validators.required)
})

listShow() {
      let value = this.addPromoCodeForm.value.discountType
      if (value == '0') {// discount
  this.isShowTextArea = 0;
      } else {//flat
        this.isShowTextArea = 1;
      }
    }

     patchPromoCodeData(){
       console.log(this.saveEditData?.discount_type)
      if (this.saveEditData?.discount_type) {
        this.isShowTextArea = 1;
      } else {
        this.isShowTextArea = 0;
      }
   this.addPromoCodeForm.patchValue({
   promoCodeTitle:this.saveEditData?.title,
    discountType: this.saveEditData?.discount_type,
    discount: this.saveEditData?.discount,
    description:this.saveEditData?.description,
    maximumDiscount:this.saveEditData?.max_discount,
    minimumOrderAmt:this.saveEditData?.minimum_order_amt,
    startDate:new Date(this.saveEditData?.start_date),
    endDate:new Date(this.saveEditData?.end_date),
    orderValueLimit:this.saveEditData?.order_value_limit
})
}

min:any
discount:any
max:any
storData:any
addPromoFormSubmit() {
  // debugger
  this.submitted = true

this.min =Number(this.addPromoCodeForm.value.minimumOrderAmt)
  this.discount=  Number(this.addPromoCodeForm.value.discount)
  this.max= Number(this.addPromoCodeForm.value.maximumDiscount)
  
    if (Date.parse(this.addPromoCodeForm.value.startDate) > Date.parse(this.addPromoCodeForm.value.endDate)) {
        this.toaster.warning("Please Select Valid Time")
        return
    }
      if (Date.parse(this.addPromoCodeForm.value.startDate) === Date.parse(this.addPromoCodeForm.value.endDate)) {
        this.toaster.warning(" offer Created Date & Expired  Date  should not be  Same")
        return
      }
    //   if (this.addPromoCodeForm.value.discountType != '0') {
    //     if(this.min < this.discount) {
    //       this.toaster.error('Discounted Amount Should be less then minimum booking charges ')
    //       return
    //  }
    //   }
      if (this.addPromoCodeForm.value.discountType != '0') {
      if(this.min < this.discount){
        this.toaster.warning("Flat discount should always less than to minimum booking charges")
        return
      }
    }
if (this.addPromoCodeForm.value.discountType == '0') {
  this.storData = Number(this.min) * Number(this.discount) / 100;
 
  if (this.storData >= Number(this.max)) {
  this.toaster.warning('Maximun  Discount Amount Should be smaller then minimum booking charges')
    return
  } 
  
}
 if (this.addPromoCodeForm.value.discountType == '0' && this.addPromoCodeForm.value.maximumDiscount == null) {
        this.toaster.warning('Please Fill  Maximum Discount Offer')
        return
  
      }
      let data = this.addPromoCodeForm.value
        data.startDate = Date.parse(this.addPromoCodeForm.value.startDate)
        data.endDate = Date.parse(this.addPromoCodeForm.value.endDate)
        data.description=this.addPromoCodeForm.value.description
        delete this.addPromoCodeForm.value.promoCodeId
        if (this.isShowTextArea == 1) {
          delete data.maximumDiscount
        }
  
      if (!this.saveEditData) {
        this.api.post('add_promocode', data).subscribe({
          next: (res: any) => {
            this.toaster.success(res.message);
            this.dialogRef.close(); // <- Close the mat dialog
  
          }, error: (err: any) => {
            console.log(err.error.message)
          }
        })
      } 
      else {
        // data.id = this.saveEditData._id
        data.promoCodeId =this.saveEditData._id
        this.api.post('edit_promocode', data).subscribe({
          next: (res: any) => {
            this.toaster.success(res.message);
            this.dialogRef.close(); // <- Close the mat dialog
          }, error: (err: any) => {
            console.log(err.error.message)
          }
        })
      }
  
    }
  

    restrictZeroAtFirstPosition(event : any){
      if(event.target.value.length === 0 && event.key == '0'){
        event.preventDefault()
      }
      // else if(event.target.value.length===3 && event.key){
  
      // }
      console.log(event)
    }

    onKeyPress(event: KeyboardEvent) {
      const inputChar = String.fromCharCode(event.keyCode);
      const pattern = /^[a-zA-Z\s]*$/;
  
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
get  f(){
      return this.addPromoCodeForm.controls
     }


     


    
}
