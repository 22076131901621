<div style="width:min-content">
    <form [formGroup]="bannerForm">
        <div class="d-header mb-3 gap-3">
            <b> {{data ? "Edit" : "Add"}} Banner</b>
            <img
                src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
                alt class="clss" mat-dialog-close>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div>
                    <p> Title</p>
                    <div>
                        <input type="text" placeholder="Title Name" class="inpt"
                            formControlName="title" appAlphabetOnly>
                    </div>
                    <p>Start Date</p>
                    <mat-form-field color="accent" appearance="fill"
                        class="my-date mat-mdc-text-field-wrapper">
                        <input matInput [matDatepicker]="picker1"
                            placeholder="DD/MM/YY" [(ngModel)]="start_date"
                            [ngModelOptions]="{standalone: true}">
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div>

                    <p>Link</p>
                    <div>
                        <input type="text" placeholder="Link Name" class="inpt"
                            formControlName="link" required>
                    </div>
                </div>
                <p>End Date</p>
                <mat-form-field color="accent" appearance="fill"
                    class="my-date mat-mdc-text-field-wrapper">
                    <input matInput [matDatepicker]="picker2"
                        placeholder="DD/MM/YY" [(ngModel)]="end_date"
                        [ngModelOptions]="{standalone: true}" [min]="start_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
            </div>

        </div>
        <div>
            <div class="profile-pic-pnl">
                <div class="profile-pic">
                    <img [src]="url">
                </div>
            </div>
        </div>
        <div class="d-flex gap-3">
            <div mat-dialog-actions>
                <input type='file' (change)="onSelectFile($event)"
                    class="fileUpload" accept="image/*">
            </div>
            <button class="save2 "><i class=" fa fa-upload mx-1"></i> <span
                    style="font-size: 15px; ">Upload</span></button>

            <button mat-button mat-dialog-close class="save"
                (click)="addAdvertisement()">{{!data ? "Save" : "Update"}}</button>

        </div>
    </form>
</div>