import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './service/auth.guard';

const routes: Routes = [
{path:'',loadChildren:()=>import('./auth/auth.module').then(m=>m.AuthModule)},
{path:'home',loadChildren:()=>import('./layout/layout.module').then(s=>s.LayoutModule),
canActivate:[AuthGuard]
}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
