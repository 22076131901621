<div>
    <div class="main-d">
        <h6>Facilities</h6>
        <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png" alt="" class="clss" mat-dialog-close>
    </div>
    
    <div mat-dialog-content>
        <ul style="width: 150px; text-align:start;">
            <li>Drinking Water</li>
            <li>Ball Boy</li>
            <li>Cafe</li>
            <li>Flood Lights</li>

        </ul>

    </div>

</div>