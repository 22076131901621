import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appExcludeSpecialCharacter]'
})
export class ExcludeSpecialCharacterDirective {

  constructor() {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const input = event.target;
    input.value = input.value.replace(/[^\w\s]/gi, '');
  }

}
