import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-delete-dialogue',
  templateUrl: './delete-dialogue.component.html',
  styleUrls: ['./delete-dialogue.component.css']
})
export class DeleteDialogueComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private api: ApiService, 
  private toaster: ToastrService) {
    console.log(data)

  }

  ngOnInit(){

  }

  // CommonDelete() {


  //   if(this.api.post(`${this.data.api}`, { _id: this.data.id })){
  //     this.api.post(`${this.data.api}`, { _id: this.data.id }).subscribe({next: (res: any) => {
  //       this.toaster.success(res.message)
  //     },
  //     error: (error: any) => {
  //       console.log(error.message)
  //       this.toaster.error(error.error.message);
  //     }
  //   })

  //   }
  //   else{
  //     this.api.delete(`${this.data.api}/${this.data.id }`).subscribe({next: (res: any) => {
  //       this.toaster.success(res.message)
  //     },
  //     error: (error: any) => {
  //       console.log(error.message)
  //       this.toaster.error(error.error.message);
  //     }
  //   })

  //   }
   
  // }
 

  CommonDelete() {
      this.api.post(`${this.data.api}`, { _id: this.data.id }).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
        },
        error: (error: any) => {
          console.log(error.message);
          this.toaster.error(error.error.message);
        }
      });
    }
    
    
  
    
    
    // else if(this.api.delete){
    //   this.api.delete(`${this.data.api}/${this.data.id}`).subscribe({
    //     next: (res: any) => {
    //       this.toaster.success(res.message);
    //     },
    //     error: (error: any) => {
    //       console.log(error.message);
    //       this.toaster.error(error.error.message);
    //     }
    //   });
    // }
  

  





}





