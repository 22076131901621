<div mat-dialog-content class="prnt">
  <div class="ground1">
       <!-- type 01 for Trade License Proof -->
    <div *ngIf="data?.type == 2">
      <div class="d-flex justify-content-between mb-3">
        <label>Trade License Id </label>

        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class="d-flex gap-2">
        <div>
          <img
            src="{{
              data?.element?.upload_trade_license?.trade_license_front
                ? data?.element?.upload_trade_license?.trade_license_front
                : url1
            }}"
            alt
            style="height: 350px; width: 400px"
            mat-dialog-close
            (click)="
              imgView(data?.element?.upload_trade_license?.trade_license_front)
            "
          />
          <!-- <img src="../../../assets/images (1).png" alt /> -->
        </div>
        <div>
          <img
            src="{{
              data?.element?.upload_trade_license?.trade_license_back
                ? data?.element?.upload_trade_license?.trade_license_back
                : url1
            }}"
            alt
            style="height: 350px; width: 400px"
            mat-dialog-close
            (click)="
              imgView(data?.element?.upload_trade_license?.trade_license_back)
            "
          />
        </div>
      </div>
    </div>

    <!-- type 02 for id Proof -->
    <div *ngIf="data?.type == 3">
      <div class="d-flex justify-content-between mb-3">
        <label>ID Proof</label>

        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class="d-flex gap-2">
        <div>
          <!-- src="{{(data.element) ? data.element : url1}}" -->
          <!-- <p (click)="imgView()">a</p> -->
          <img
            src="{{
              data?.element?.upload_id?.id_front
                ? data?.element?.upload_id?.id_front
                : url1
            }}"
            alt
            style="height: 350px; width: 400px"
            mat-dialog-close
            (click)="imgView(data?.element?.upload_id?.id_front)"
          />
          <!-- <img src="../../../assets/Sportsbay_Vendor_Web_Panel_Assets/com.png"alt > -->
        </div>
        <div>
          <img
            src="{{
              data?.element?.upload_id?.id_back
                ? data?.element?.upload_id?.id_back
                : url1
            }}"
            alt
            style="height: 350px; width: 400px"
            mat-dialog-close
            (click)="imgView(data?.element?.upload_id?.id_back)"
          />
          <!-- <img src="../../../assets/Sportsbay_Vendor_Web_Panel_Assets/com.png"alt style="height: 180px; width: 200px;"> -->
        </div>
      </div>
    </div>

    <!-- type 03 for  Registration Reject/Rejected colomn-->
    <div *ngIf="data?.type == 4">
      <div class="d-flex justify-content-between mb-5">
        <div>
          <i
            class="fa fa-chevron-left mx-2"
            aria-hidden="true"
            mat-dialog-close
          ></i>
          <label style="font-size: 20px">Reason</label>
        </div>

        <div>
          <img
            src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
            alt
            mat-dialog-close
          />
        </div>
      </div>
      <div>
        <form [formGroup]="selectReason">
          <label style="font-size: 15px">Select Reason </label>

          <!-- <select class="my-drop mx-3" formControlName="reason">
            <option value selected hidden>Select Reason</option>
            <option *ngFor="let reason of rejectReasons">
              {{ reason }}
            </option>
          </select> -->
          <input type="text" placeholder="reason" formControlName="reason">
        </form>
      </div>
      <button class="save2 mt-5" (click)="selectReasonButton()">Submit</button>
    </div>

    <!-- type 04 for  Add City colomn-->
    <div *ngIf="data?.type == 5" style="width: auto">
      <form [formGroup]="cityForm">
        <div class="d-flex justify-content-between mb-5">
          <div>
            <i
              class="fa fa-chevron-left mx-2"
              aria-hidden="true"
              mat-dialog-close
            ></i>
            <label style="font-size: 20px">Add City</label>
          </div>
          <div>
            <img
              src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
              alt
              class
              mat-dialog-close
            />
          </div>
        </div>
        <!-- <div class>
          <mat-form-field class="my-drop mx-3">
            <mat-select
              placeholder="Select Country"
              formControlName="countryISO"
            >
              <mat-option value="AE">United Arab Emirates</mat-option>

            </mat-select>
          </mat-form-field>
        </div> -->
        <div class>
          <label style="font-size: 17px; margin-right: 38px" for="email"
              >Select City :</label
            >
          <div class="mt-3">
            
            <!-- <input type="text" placeholder="City Name"
                        style="text-align: left; margin: 0 1rem; width: 65%;"> -->
            <mat-form-field class="my-drop mx-3" appearance="fill" [readonly]="!disableBlock">
              <!-- <mat-select [(value)]="selected" formControlName="cityName" onselect ="disable(0)" > -->
              <mat-select [(value)]="selected" formControlName="cityName" >

                <!-- <mat-option value="">select City</mat-option> -->
                <mat-option *ngFor="let city of cityList" [value]="city.name">{{
                  city.name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class = "mt-2"> OR </div>
            <!-- <input type="my-drop mx-3" placeholder="Enter city name here...." (click)="disable(1)" formControlName="cityName" readonly="disableBlock" > -->
            <input type="my-drop mx-3" placeholder="Enter city name here...."  formControlName="cityName"  >

          </div>
        </div>
        <button class="save2 mt-5" mat-dialog-close (click)="addCity()">
          Save
        </button>
      </form>
    </div>

    <!-- type 5 for  View Venue Details-->
    <div *ngIf="data?.type == 6" class="px-4">
      <div class="d-flex justify-content-between">
        <div>
          <i
            class="fa fa-chevron-left mx-2"
            aria-hidden="true"
            mat-dialog-close
          ></i>
          <label style="font-size: 20px">Venue Details</label>
        </div>
        <div>
          <img
            src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
            alt
            class
            mat-dialog-close
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="d-flex pt-3">
            <h6>Image</h6>
          </div>
          <div class="right-s">
            <div class="file-upload">
              <img
                src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
                alt
                class="clss"
              />

              <label class="cmrdiv">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                <input
                  type="file"
                  name="somename"
                  (change)="multipleFiles1($event)"
                  accept="image/*"
                  class="cursor-pointer"
                />
              </label>
              <div class="img">
                <img
                  *ngIf="!profile_image"
                  src="assets/Sportsbay_Vendor_Web_Panel_Assets/com.png"
                />
                <img *ngIf="profile_image" src="{{ profile_image }}" />
              </div>
            </div>
            <div class="file-upload">
              <img
                src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
                alt
                class="clss"
              />

              <label class="cmrdiv">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                <input
                  type="file"
                  name="somename"
                  (change)="multipleFiles1($event)"
                  accept="image/*"
                  class="cursor-pointer"
                />
              </label>
              <div class="img">
                <img
                  *ngIf="!profile_image"
                  src="assets/Sportsbay_Vendor_Web_Panel_Assets/com.png"
                />

                <img *ngIf="profile_image" src="{{ profile_image }}" />
              </div>
            </div>

            <div class="file-upload">
              <img
                src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
                alt
                class="clss"
              />

              <label class="cmrdiv">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                <input
                  type="file"
                  name="somename"
                  (change)="multipleFiles1($event)"
                  accept="image/*"
                  class="cursor-pointer"
                />
              </label>
              <div class="img">
                <img
                  *ngIf="!profile_image"
                  src="assets/Sportsbay_Vendor_Web_Panel_Assets/com.png"
                />
                <img *ngIf="profile_image" src="{{ profile_image }}" />
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex pt-1">
              <h6>Short Video</h6>
            </div>
            <div class="d-flex gap-5">
              <div class="file-upload">
                <img
                  src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
                  alt
                  class="clss"
                />
                <label class="cmrdiv">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </label>
                <div class="img">
                  <!-- <img src="assets/bg.jfif" />
                                            <mat-icon class="v-icon">play_circle_outline</mat-icon> -->
                  <video width="140" height="100" controls>
                    <source
                      src="../../../assets/Sportsbay_Vendor_Web_Panel_Assets/video_20230823_180220.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div class="py-2">
            <div class="d-flex gap-4">
              <h6>>{{ data.bank_details?.account_holder_name }}</h6>
            </div>
            <div class>
              <ul style="list-style: none; text-align: start">
                <li>BallBoy</li>
                <li>Cafe</li>
              </ul>
            </div>
          </div>
          <div class="py-2">
            <div class="d-flex gap-4">
              <h6>Amenities</h6>
            </div>
            <div class>
              <ul style="list-style: none; text-align: start">
                <li>BallBoy</li>
                <li>Cafe</li>
              </ul>
            </div>
          </div>
          <div class="py-2">
            <div class="d-flex gap-4">
              <h6>Opening & Closing</h6>
            </div>
            <div class>
              <ul style="list-style: none; text-align: start">
                <li>BallBoy</li>
                <li>Cafe</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button class="save2" mat-dialog-close>Save</button>
    </div>

    <!-- type 06 for Vanue Details colomn-->
    <div mat-dialog-content *ngIf="data?.type == 7" class="px-4">
      <div class="d-flex justify-content-between">
        <div>
          <label>Venue Details</label>
        </div>
        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class="modal-body">
        <table class="table">
          <tr>
            <td>Bank Account Number :</td>
            <td>hii</td>
          </tr>
          <tr>
            <td>Bank Name :</td>
            <td>hlw</td>
          </tr>
          <tr>
            <td>Bank Code :</td>
            <td>hh</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- type 07 for View Access colomn OF SUBADMIN-MGMT-->
    <div mat-dialog-content *ngIf="data?.type == 8" class="px-4">
      <div class="d-flex justify-content-between">
        <div>
          <label>View Access</label>
        </div>
        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class="modal-body">
        <table class="table text-start direction">
          <tr>
            <td>
              {{
                data.element.moduleAccess[0] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[0] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[0] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[0] == 4
                  ? "User Management"
                  : data.element.moduleAccess[0] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[0] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[0] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[0] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[0] == 9
                  ? "City Management"
                  : data.element.moduleAccess[0] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[0] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[0] == 12
                  ? " advertisement Management"
                  : " "
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[1] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[1] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[1] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[1] == 4
                  ? "User Management"
                  : data.element.moduleAccess[1] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[1] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[1] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[1] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[1] == 9
                  ? "City Management"
                  : data.element.moduleAccess[1] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[1] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[1] == 12
                  ? " advertisement Management"
                  : " "
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[2] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[2] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[2] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[2] == 4
                  ? "User Management"
                  : data.element.moduleAccess[2] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[2] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[2] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[2] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[2] == 9
                  ? "City Management"
                  : data.element.moduleAccess[2] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[2] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[2] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[3] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[3] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[3] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[3] == 4
                  ? "User Management"
                  : data.element.moduleAccess[3] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[3] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[3] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[3] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[3] == 9
                  ? "City Management"
                  : data.element.moduleAccess[3] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[3] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[3] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[4] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[4] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[4] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[4] == 4
                  ? "User Management"
                  : data.element.moduleAccess[4] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[4] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[4] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[4] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[4] == 9
                  ? "City Management"
                  : data.element.moduleAccess[4] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[4] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[4] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[5] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[5] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[5] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[5] == 4
                  ? "User Management"
                  : data.element.moduleAccess[5] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[5] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[5] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[5] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[5] == 9
                  ? "City Management"
                  : data.element.moduleAccess[5] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[5] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[5] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[6] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[6] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[6] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[6] == 4
                  ? "User Management"
                  : data.element.moduleAccess[6] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[6] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[6] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[6] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[6] == 9
                  ? "City Management"
                  : data.element.moduleAccess[6] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[6] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[6] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[7] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[7] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[7] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[7] == 4
                  ? "User Management"
                  : data.element.moduleAccess[7] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[7] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[7] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[7] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[7] == 9
                  ? "City Management"
                  : data.element.moduleAccess[7] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[7] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[7] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[8] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[8] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[8] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[8] == 4
                  ? "User Management"
                  : data.element.moduleAccess[8] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[8] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[8] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[8] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[8] == 9
                  ? "City Management"
                  : data.element.moduleAccess[8] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[8] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[8] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[9] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[9] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[9] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[9] == 4
                  ? "User Management"
                  : data.element.moduleAccess[9] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[9] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[9] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[9] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[9] == 9
                  ? "City Management"
                  : data.element.moduleAccess[9] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[9] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[9] == 12
                  ? " advertisement Management "
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[10] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[10] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[10] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[10] == 4
                  ? "User Management"
                  : data.element.moduleAccess[10] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[10] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[10] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[10] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[10] == 9
                  ? "City Management"
                  : data.element.moduleAccess[10] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[10] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[10] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[11] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[11] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[11] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[11] == 4
                  ? "User Management"
                  : data.element.moduleAccess[11] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[11] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[11] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[11] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[11] == 9
                  ? "City Management"
                  : data.element.moduleAccess[11] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[11] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[11] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
            <td>
              {{
                data.element.moduleAccess[12] == 1
                  ? "Dashboard Management"
                  : data.element.moduleAccess[12] == 2
                  ? "Booking Management"
                  : data.element.moduleAccess[12] == 3
                  ? "Promocode Management"
                  : data.element.moduleAccess[12] == 4
                  ? "User Management"
                  : data.element.moduleAccess[12] == 5
                  ? "Reviews Management"
                  : data.element.moduleAccess[12] == 6
                  ? "vendor Management"
                  : data.element.moduleAccess[12] == 7
                  ? "Payment Management"
                  : data.element.moduleAccess[12] == 8
                  ? "Reports Management"
                  : data.element.moduleAccess[12] == 9
                  ? "City Management"
                  : data.element.moduleAccess[12] == 10
                  ? "CMS Management"
                  : data.element.moduleAccess[12] == 11
                  ? "Sports categoty Management"
                  : data.element.moduleAccess[12] == 12
                  ? " advertisement Management"
                  : ""
              }}
            </td>
            <br />
          </tr>
        </table>
      </div>
    </div>

    <!-- type 08 for View Access colomn OF PAYMENT MGMT-->
    <div mat-dialog-content *ngIf="data?.type == 9" class="px-4">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <label>Payment Details</label>
        </div>
        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class>
        <!-- <div class="second-div">
          <div style="border-bottom: 2px solid #338a2a; text-align: left">
            <span>User Details</span>
          </div>
          <div class>
            <div class="profile-details">
              <table class="table" id="table11">
                <tr>
                  <td>User Name :</td>
                  <td>{{ elementData?.user_details?.user_name }}</td>
                </tr>
                <tr>
                  <td>Email ID:</td>
                  <td>{{ elementData?.user_details?.email }}</td>
                </tr>
                <tr>
                  <td>Mobile Number:</td>
                  <td>{{ elementData?.user_details?.mobile_no }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="second-div">
          <div style="border-bottom: 2px solid #338a2a; text-align: left">
            <span>Vendor Details</span>
          </div>
          <div class>
            <div class="profile-details">
              <table class="table" id="table11">
                <tr>
                  <td>Name :</td>
                  <td>
                    {{
                      elementData?.ground_details?.venue_id?.vendor_id
                        ?.bussiness_name
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Email Address:</td>
                  <td>
                    {{
                      elementData?.ground_details?.venue_id?.vendor_id?.email
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Mobile Number:</td>
                  <td>
                    {{
                      elementData?.ground_details?.venue_id?.vendor_id
                        ?.mobile_number
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="second-div">
          <div style="border-bottom: 2px solid #338a2a; text-align: left">
            <span>Booking Details</span>
          </div>
          <div class>
            <div class="profile-details">
              <table class="table" id="table11">
                <tr>
                  <td>Booking ID :</td>
                  <td>{{ elementData?.booking_id }}</td>
                </tr>
                <tr>
                  <td>Ground Name:</td>
                  <td>
                    {{ elementData?.ground_details?.venue_id?.venue_name }}
                  </td>
                </tr>
                <tr>
                  <td>Date & Time:</td>
                  <td>{{ elementData?.updatedAt | date }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div> -->

        <div class="second-div">
          <div style="border-bottom: 2px solid #338a2a; text-align: left">
            <span>Payment Details</span>
          </div>
          <div class>
            <div class="profile-details">
              <table class="table" id="table11">
                <tr>
                  <td style="width: 50%">Trasantion ID :</td>
                  <td style="width: 50%">
                    {{ elementData?.payment_details?.transaction_id }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">Amount:</td>
                  <td style="width: 50%">
                    AED {{ elementData?.order_details?.total }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">Admin Fee:</td>
                  <td style="width: 50%">
                    AED {{ elementData?.order_details?.subtotal_price }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- type 01 for Booking ID colomn OF PAYMENT MGMT-->
  <div mat-dialog-content *ngIf="data?.type == 1" class="px-4">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <label>Booking Details</label>
      </div>
      <img
        src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
        alt
        class
        mat-dialog-close
      />
    </div>
    <div class>
      <div class="second-div">
        <div style="border-bottom: 2px solid #338a2a; text-align: left">
          <span>User Details</span>
        </div>
        <div class>
          <div class="profile-details">
            <table class="table" id="table11">
              <tr>
                <td>User Name :</td>
                <td>{{ elementData?.user_details?.user_name }}</td>
              </tr>
              <tr>
                <td>Email ID:</td>
                <td>{{ elementData?.user_details?.email }}</td>
              </tr>
              <tr>
                <td>Mobile Number:</td>
                <td>{{ elementData?.user_details?.mobile_no }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="second-div">
        <div style="border-bottom: 2px solid #338a2a; text-align: left">
          <span>Vendor Details</span>
        </div>
        <div class>
          <div class="profile-details">
            <table class="table" id="table11">
              <tr>
                <td>Name :</td>
                <td>
                  {{
                    elementData?.ground_details?.venue_id?.vendor_id
                      ?.bussiness_name
                  }}
                </td>
              </tr>
              <tr>
                <td>Email Address:</td>
                <td>
                  {{
                    elementData?.ground_details?.venue_id?.vendor_id?.email
                  }}
                </td>
              </tr>
              <tr>
                <td>Mobile Number:</td>
                <td>
                  {{
                    elementData?.ground_details?.venue_id?.vendor_id
                      ?.mobile_number
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- <div class="second-div">
        <div style="border-bottom: 2px solid #338a2a; text-align: left">
          <span>Booking Details</span>
        </div>
        <div class>
          <div class="profile-details">
            <table class="table" id="table11">
              <tr>
                <td>Booking ID :</td>
                <td>{{ elementData?.booking_id }}</td>
              </tr>
              <tr>
                <td>Ground Name:</td>
                <td>
                  {{ elementData?.ground_details?.venue_id?.venue_name }}
                </td>
              </tr>
              <tr>
                <td>Date & Time:</td>
                <td>{{ elementData?.updatedAt | date }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>  -->

    </div>
  </div>

    <!-- type 09 for  SHOW FULLIMAGE colomn-->
    <div *ngIf="data?.type == 12" style="padding: 0">
      <div style="overflow: hidden">
        <img
          src="{{ data.element ? data.element : url1 }}"
          alt
          class="setimg"
        />
      </div>
    </div>

    <!-- type 10 for  Ground Details colomn RATING MGMT-->
    <div *ngIf="data?.type == 13" style="padding: 0">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <label>Ground Details</label>
        </div>
        <img
          src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
          alt
          class
          mat-dialog-close
        />
      </div>
      <div class="modal-body">
        <table class="table" style="text-align: start">
          <tr>
            <td>Ground Name : </td>
            <td>&nbsp;{{elementData?.booking_id?.ground_details?.playground_id?.title}}</td>
          </tr>
          <tr>
            <td>Court Size : </td>
            <td>{{elementData?.booking_id?.ground_details?.court_id?.court_capacity}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- ============================ 11 ======= commsion  dailog =============== -->
<div *ngIf="data?.type == 14">
  <div class="d-flex justify-content-between mb-3">
    <label>Commission</label>

    <img
      src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
      alt
      class
      mat-dialog-close
    />
  </div>

  <mat-body class="make-text-start">
    <form [formGroup]="commissionForm">
      <p class="text-start">Enter Commission</p>
      <input
        type="text"
        minlength="1"
        maxlength="2"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        formControlName="commisson"
        placeholder="commission"
        class="w-91 mb-3"
      />
    </form>
    <div class="btns w-30" (click)="addCommssion()">Submit</div>
  </mat-body>

  <!-- <div class="btns w-30 " (click)=addCommssion()>Submit</div> -->
</div>


