import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent {
  public dialogRef!: MatDialogRef<BankDetailsComponent>; 
  // @Inject(MAT_DIALOG_DATA) public data: DialogData,
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  ngOnInit(){
    console.log(this.data)
  }  

onNoClick(): void {
  this.dialogRef.close();
}
}
