import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-accept",
  templateUrl: "./accept.component.html",
  styleUrls: ["./accept.component.css"],
})
export class AcceptComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private toaster: ToastrService
  ) {
    console.log(data);
  }
  // ....................................For accept vendor...................
  value: any;
  acceptVendor() {
    this.value = {
      vendorId: this.data.vendorId,
      vendorStatus: this.data.vendorStatus,
    };
    this.api.post(`${this.data.api}`, this.value).subscribe({
      next: (res: any) => {
        this.toaster.success(res.message);
      },
      error: (error: any) => {
        console.log(error.message);
        this.toaster.error(error.error.message);
      },
    });
  }
}
