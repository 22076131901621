<div mat-dialog-content class="prnt">
    <div class="d-header">
        <div>
            <label>Bank Details</label>

        </div>
        <img src="assets/Sportsbay_Vendor_Web_Panel_Assets/booking_reject.png"
            alt class="clss" mat-dialog-close>
    </div>
    <div class="modal-body">
        <table class="table">
            <tr>
                <td> Account Holder Name : </td>
                <td>{{data.bank_details?.account_holder_name}}</td>
            </tr>
            <tr>
                <td>Bank IBAN : </td>
                <td>{{data.bank_details?.iban}} </td>
            </tr>
            
            <tr>
                <td>Bank Name : </td>
                <td>{{data.bank_details?.bank_name}} </td>
            </tr>
            <tr>
                <td>Bank Swift Code : </td>
                <td>{{data.bank_details?.swift_code}}</td>
            </tr>
            <tr>
                <td>Account Holder Address : </td>
                <td>{{data.bank_details?.account_holder_address}}</td>
            </tr>
            <tr>
                <td>Tax Registration Number : </td>
                <td>{{data.bank_details?.trn_no ? data.bank_details?.trn_no :'N/A'}}</td>
            </tr>
        </table>

    </div>
</div>
