import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgChartsModule } from "ng2-charts";
import { CategoryComponent } from "./dialog/category/category.component";
import { CommanComponent } from "./dialog/comman/comman.component";
import { MaterialModule } from "./material/material.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { AuthInterceptorService } from "./service/auth-interceptor.service";
import { BankDetailsComponent } from "./dialog/bank-details/bank-details.component";
import { AddBannerComponent } from "./dialog/add-banner/add-banner.component";
import { AddPromoComponent } from "./dialog/add-promo/add-promo.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlphabetOnlyDirective } from "./alphabet-only.directive";
import { FacilitiesComponent } from "./dialog/facilities/facilities.component";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { DeleteDialogueComponent } from "./dialog/delete-dialogue/delete-dialogue.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AcceptComponent } from './dialog/accept/accept.component';
import { ImgViewComponent } from './dialog/img-view/img-view.component';
import { SpinnerService } from "./service/spinner.service";
import { UppercaseDirective } from './directives/uppercase.directive';
import { LowercaseDirective } from './directives/lowercase.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyAlphanumericDirective } from './directives/only-alphanumeric.directive';
import { ExcludeSpecialCharacterDirective } from './directives/exclude-special-character.directive';
import { RemoveWhiteSpaceDirective } from './directives/remove-white-space.directive';

@NgModule({
  declarations: [
    AppComponent,
    CategoryComponent,
    CommanComponent,
    BankDetailsComponent,
    AddBannerComponent,
    AddPromoComponent,
    AlphabetOnlyDirective,
    FacilitiesComponent,
    DeleteDialogueComponent,
    AcceptComponent,
    ImgViewComponent,
    UppercaseDirective,
    LowercaseDirective,
    OnlyNumberDirective,
    OnlyAlphanumericDirective,
    ExcludeSpecialCharacterDirective,
    RemoveWhiteSpaceDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgChartsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    
    MatProgressButtonsModule.forRoot(),
    
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      timeOut: 3000,
      // positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      easeTime: 1000,
      // iconClasses = {
      //   error: 'toast-error',
      //   info: 'toast-info',
      //   success: 'toast-success',
      //   warning: 'toast-warning',
      // }
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      multi: true,
      useClass: AuthInterceptorService,
      provide: HTTP_INTERCEPTORS,
    },
    SpinnerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
